<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">OJT Criteria</h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-4">
              <!-- advance serach -->
              <div class="d-flex">
                <div class="d-flex flex-column me-2">
                  <label class="col-form-label">Program</label>
                  <div style="width: 250px">
                    <v-select
                      label="name"
                      v-model="program"
                      :options="programList"
                      :clearable="false"
                      :selectable="(options) => program.id != options.id"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="d-flex flex-column me-2">
                  <label class="col-form-label">Level</label>
                  <div style="width: 170px">
                    <v-select
                      label="name"
                      v-model="level"
                      :options="getLevel"
                      :clearable="false"
                      :selectable="(options) => level.name != options.name"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="d-flex flex-column">
                  <label class="col-form-label">Status</label>
                  <div style="width: 170px">
                    <v-select
                      label="name"
                      v-model="status"
                      :options="arr_status"
                      :clearable="false"
                      :selectable="(options) => status.name != options.name"
                      @option:selected="FilterData(program, level, status)"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <!-- <div class="col-form-label d-none">hfhd</div> -->
                  <div @click="refreshData()" class="icon-css">
                    <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                  </div>
                </div>
              </div>
              <!--end advance serach -->

              <div class="d-flex justify-content-end">
                <router-link :to="{ name: 'ojt-criteria-create' }">
                  <button
                    v-show="!loading"
                    type="button"
                    class="btn btn-sm btn-blue waves-effect waves-light"
                     v-if="hasPermissions(['hrd-ojt-criteria-create'])"
                  >
                    <i class="mdi mdi-plus-circle"></i> Add Criteria
                  </button>
                </router-link>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>

            <DataTable
              v-else
              table="dataTable"
              :data="criteria"
              :columns="column"
              route="ojt-criteria-update"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../../components/shared/DataTable.vue";

import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { levels } from "../../../const";
import { hrdTypesList } from "../../../utlis/hrd_types";

export default {
  components: {
    vSelect,
    DataTable,
  },

  mixins: [userHasPermissions],

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      criteria: [],
      arr_status: [{ name: "Active" }, { name: "Inactive" }],
      programList: this.$store.getters["odoo/getAllPrograms"],
      getLevel: levels,
      program: "",
      status: "",
      number : 0,
      level: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,

      column: [
        {
          data: "id",
          title: "No",
          render: () => {
            this.number++;
            return this.number;
          },
        },
        { data: "name", title: "Criteria" },
        {
          data: "programs",
          title: "Program Name",
          render: (data) => {
            let str = "";
            for (let val of data) {
              str += val.code + ", ";
            }
            return str.slice(0, -2);
          },
        },
        { data: "level", title: "Level" },
        {
          data: null,
          title: "Hrd Type",
          render: (data) => {
            if (data.hrd_type) {
              const matchedHrd = hrdTypesList.find(hrd => data.hrd_type == hrd.value);
              return matchedHrd ? matchedHrd.name : "Behavior Assessment";
            } else {
              return "Behavior Assessment";
            }
          }
        },
        { data: "lowest_score", title: "Lowest Score" },
        { data: "highest_score", title: "Highest Score" },
        {
          data: null,
          title: "Status",
          render: (data) => {
            return data.status
              ? `<span class="badge bg-success p-1" style="width: 65px;">Active</span>`
              : `<span class="badge bg-danger p-1" style="width: 65px;">Inactive</span>`;
          },
        },
        {
          data: null,
          title: "Action",
          render: () => {
            return `<i class="mdi mdi-square-edit-outline edit-icon hover fs-4"></i>`;
          },
        },
      ],
    };
  },

  methods: {
    // In-Class
    async getAllCriteria() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/hrd-criteria?type=OJT`)
        .then((response) => {
          this.criteria = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#dataTable").DataTable().draw();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().destroy();
      $("#dataTable").DataTable().draw();
    },

    refreshData() {
      this.clearFilter();
      this.number = 0;
      this.status = "";
      this.criteria = [];
      this.level = "";
      this.program = "";
      this.getAllCriteria();
    },

    FilterData(program, level, status) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_program = data[2].includes(program?.short_name)
          ? true
          : false;
        const check_level = level?.name == data[3] ? true : false;
        const check_status = status?.name == data[6] ? true : false;
        if (
          (check_status || !status.name) &&
          (check_level || !level.name) &&
          (check_program || !program.short_name)
        ) {
          return true;
        }

        return false;
      });

      $("#dataTable").DataTable().draw();
    },
  },

  async created() {
    await this.clearFilter();
    await this.getAllCriteria();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
